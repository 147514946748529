var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mx-3 mb-3"},[_c('v-skeleton-loader',{staticStyle:{"height":"100%"},attrs:{"type":"list-item-three-line","loading":!_vm.loaded,"transition":"fade-transition"}},[_c('v-card',{attrs:{"flat":""}},[_c('v-card-title',[_vm._v(" Users "),_c('v-spacer'),_c('v-btn',{staticStyle:{"text-transform":"none"},attrs:{"depressed":"","color":"primary"},on:{"click":_vm.newUser}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus")]),_vm._v(" Add new ")],1)],1),_c('v-card-subtitle',[_vm._v(" All users in one place ")]),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"xs":"12","sm":"12","md":"12","lg":"6","xl":"6","offset-lg":"6","offset-xl":"6"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Filter","clearable":"","single-line":"","hide-details":""},model:{value:(_vm.filterUsers),callback:function ($$v) {_vm.filterUsers=$$v},expression:"filterUsers"}})],1)],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.users,"search":_vm.filterUsers,"footer-props":_vm.paginationOptions},on:{"click:row":_vm.toUser},scopedSlots:_vm._u([{key:"item.fullName",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.firstName)+" "+_vm._s(item.lastName)+" ")]}},{key:"item.phone",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatPhoneNum( item.phone ))+" ")]}},{key:"item.isSuper",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.privileges.superAdmin === true ? 'Yes' : 'No')+" ")]}},{key:"item.lastLogin",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate( item.lastLogin, 'YYYY-MM-DD @ hh:mm a' ))+" ")]}},{key:"item.active",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{class:item.active ? 'success' : 'error',attrs:{"small":""}},[_vm._v(_vm._s(item.active ? 'Yes' : 'No'))])]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }