<template>
    <div class="mx-3 mb-3">
        <v-skeleton-loader type="list-item-three-line" style="height:100%;" :loading="!loaded" transition="fade-transition">
            <v-card flat>
                <v-card-title>
                    Users
                    <v-spacer></v-spacer>
                    <v-btn depressed @click="newUser" color="primary" style="text-transform:none">
                        <v-icon left>mdi-plus</v-icon>
                        Add new
                    </v-btn>
                </v-card-title>
                <v-card-subtitle>
                    All users in one place
                </v-card-subtitle>
                <v-card-text>
                    <v-row>
                        <v-col xs="12" sm="12" md="12" lg="6" xl="6" offset-lg="6" offset-xl="6">
                            <v-text-field v-model="filterUsers" append-icon="mdi-magnify" label="Filter" clearable single-line hide-details></v-text-field>
                        </v-col>
                    </v-row>
                    <v-data-table :headers="headers" :items="users" :search="filterUsers" :footer-props="paginationOptions"
                        @click:row="toUser">
                        <template v-slot:item.fullName="{ item }">
                            {{ item.firstName }} {{ item.lastName }}
                        </template>
                        <template v-slot:item.phone="{ item }">
                            {{ formatPhoneNum( item.phone ) }}
                        </template>
                        <template v-slot:item.isSuper="{ item }">
                            {{ item.privileges.superAdmin === true ? 'Yes' : 'No' }}
                        </template>
                        <template v-slot:item.lastLogin="{ item }">
                            {{ formatDate( item.lastLogin, 'YYYY-MM-DD @ hh:mm a' ) }}
                        </template>
                        <template v-slot:item.active="{ item }">
                            <v-chip small :class="item.active ? 'success' : 'error'">{{ item.active ? 'Yes' : 'No' }}</v-chip>
                        </template>
                    </v-data-table>
                </v-card-text>
            </v-card>
        </v-skeleton-loader>

    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
    data: () => ({
        headers             : [
            { text: 'Name', value: 'fullName', sortable: true },
            { text: 'Email', value: 'email', sortable: true },
            { text: 'Phone', value: 'phone', sortable: true },
            { text: 'Is Super', value: 'isSuper', sortable: true },
            { text: 'Last Login', value: 'lastLogin', sortable: true },
            { text: 'Active', value: 'active', sortable: true },
        ],
        paginationOptions   : { itemsPerPageOptions: [ 25, 50, -1 ] },
        loaded              : false,
        filterUsers         : '',

        userDialog          : false,
    }),
    computed: {
        ...mapGetters({
            users           : 'users/users',
            needRefresh     : 'users/needRefresh',
        })
    },
    mounted() {
        this.init();
    },
    methods: {
        ...mapActions({
            loadUsers       : 'users/load',
        }),
        async init() {
            try {
                this.loaded = false;
                await this.loadUsers();
                this.loaded = true;
            }
            catch(error) {
                this.loaded = false;
                this.mxShowMessage( error, 'error' );
            }
        },
        newUser() {},
        toUser(item) {
            this.$router.push(`/su/users/${item.id}`);
        },
    }
}
</script>

<style lang="scss" scoped>

</style>